import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import beautify from "xml-beautifier";
import {statics } from 'assets/statics'

moment.locale('sk')

const baseURL = 'https://mrrose.ozeman.eu/api/'

// OLD App.tsx file

export const App: React.FC = () => {
    const [token, setToken] = useState(null)
    const [tokenInput, setTokenInput] = useState('')
    const [dates, setDates] = useState([])
    const [currentShop, setCurrentShop] = useState("5cd421f2-2175-4a8a-bc70-9425b19a93f8")

    useEffect(() => {
        getDates()
    }, [])

    const authorize = async () => {
        axios
            .post(baseURL + 'authorize', { password: tokenInput })
            .then((res) => {
                setToken(res.data.data)
            })
            .catch((e) => {
                setToken(null)
                alert('Unauthorized')
                console.error(e)
            })
    }

    const deleteDate = async (index: number) => {
        axios
            .post(baseURL + 'deleteDate', { token: token, index: index })
            .then((res) => {
                setDates(res.data.data)
            })
            .catch((e) => {
                setToken(null)
                alert('Unauthorized')
                console.error(e)
            })
    }

    const addDate = async (date: Date | null) => {
        axios
            .post(baseURL + 'addDate', { token: token, date: date })
            .then((res) => {
                setDates(res.data.data)
            })
            .catch((e) => {
                setToken(null)
                alert('Unauthorized')
                console.error(e)
            })
    }

    const getDates = async () => {
        axios
            .get(baseURL + 'dates')
            .then((res) => {
                setDates(res.data.data)
            })
            .catch((e) => {
                console.error(e)
            })
    }
    const escapeXML = (unsafe) => {
        return unsafe.replace(/&/g, '&amp;')
               .replace(/</g, '&lt;')
               .replace(/>/g, '&gt;')
               .replace(/"/g, '&quot;')
               .replace(/'/g, '&apos;');
  };
    const getXmlFeed = async () => {
        const API_URL = "https://api.mr-rose.com";

        axios
        .get("https://api.mr-rose.com/shops/"+currentShop+"/products?page=0&page_size=1000&productType=FLOWER_BUNCH&country=SK&locale=sk")
        .then((res) => {
            var doc = document.implementation.createDocument("", "", null);
            let rss = doc.createElement('rss');
            rss.setAttribute("version","2.0")
            rss.setAttribute("xmlns:g","http://base.google.com/ns/1.0")
            rss.setAttribute("xmlns:atom","http://www.w3.org/2005/Atom")

            let channel = doc.createElement('channel')
            let channelTitle = doc.createElement("title")
            channelTitle.innerHTML = "Mr. Rose donaska kvetov"
            channel.appendChild(channelTitle);

            let channelDescription = doc.createElement("description")
            channelDescription.innerHTML = "Mr. Rose FB product feed"
            channel.appendChild(channelDescription);

            let channelLink = doc.createElement("link")
            channelLink.innerHTML = statics[currentShop].webLink
            channel.appendChild(channelLink);

            let channelAtomLink = doc.createElement("atom:link")
            channelAtomLink.setAttribute("href", statics[currentShop].webUrl)
            channelAtomLink.setAttribute("rel","self")
            channelAtomLink.setAttribute("type", "application/rss+xml")
            channel.appendChild(channelAtomLink);
            
            res.data.products.forEach(product => {
                var itemsElem = doc.createElement("item");
                
                var idEl = doc.createElement("g:id");
                idEl.innerHTML = product.productID
                itemsElem.appendChild(idEl);
                
                var titleEl = doc.createElement("g:title");
                titleEl.innerHTML = escapeXML(product.name)
                itemsElem.appendChild(titleEl);
                
                var decriptionEl = doc.createElement("g:description");
                decriptionEl.innerHTML = escapeXML(product.text)
                itemsElem.appendChild(decriptionEl);
                
                var linkEl = doc.createElement("g:link");
                linkEl.innerHTML = statics[currentShop].webUrl + "/product/" + product.url
                itemsElem.appendChild(linkEl);
                
                var imageEl = doc.createElement("g:image_link");
                imageEl.innerHTML =  API_URL + '/images/' + product.imageKey + '?mode=medium' 
                itemsElem.appendChild(imageEl);
                
                var availabilityEl = doc.createElement("g:availability");
                availabilityEl.innerHTML = "in stock"
                itemsElem.appendChild(availabilityEl);
                
                var conditionEl = doc.createElement("g:condition");
                conditionEl.innerHTML = "new"
                itemsElem.appendChild(conditionEl);

                var priceEl = doc.createElement("g:price");
                priceEl.innerHTML = product.invoiceCost + " EUR";
                itemsElem.appendChild(priceEl);
                
                var salePriceEl = doc.createElement("g:sale_price");
                salePriceEl.innerHTML = product.shopDiscount + product.invoiceCost + " EUR"
                itemsElem.appendChild(salePriceEl);
                
                var brandEl = doc.createElement("g:brand");
                brandEl.innerHTML = "mrrose"
                itemsElem.appendChild(brandEl);
                
                channel.appendChild(itemsElem);
            })
    
            rss.appendChild(channel);
            doc.appendChild(rss);
            var serializer = new XMLSerializer();
            
            let xml =  beautify('<?xml version="1.0" encoding="utf-8"?>' + serializer.serializeToString(doc))
            const file = new Blob([xml], {type: 'xml'});
            let a = document.createElement('a');
            a.href = URL.createObjectURL(file);
            a.download = `product_feed_${statics[currentShop].name}.xml`;
            a.click();
        })
        .catch((e) => {
            console.error(e)
        })
    }


    const getXmlSitemap = async () => {
        const fakeStaticsDate = '2022-07-01T21:58:01.752Z';
        axios
        .get("https://api.mr-rose.com/shops/"+currentShop+"/products?page=0&page_size=1000&productType=FLOWER_BUNCH&country=SK&locale=sk")
        .then((res) => {
            var doc = document.implementation.createDocument("", "", null);
            let rss = doc.createElement('urlset');
            rss.setAttribute("xmlns","http://www.sitemaps.org/schemas/sitemap/0.9")
            rss.setAttribute("xmlns:xhtml", "http://www.w3.org/1999/xhtml");

            statics[currentShop].websites.forEach(product => {
                var itemsElem = doc.createElement("url");
                
                var locEl = doc.createElement("loc");
                locEl.innerHTML = product
                itemsElem.appendChild(locEl);
                
                var lastMod = doc.createElement("lastmod");
                lastMod.innerHTML =  fakeStaticsDate
                itemsElem.appendChild(lastMod);
                
                rss.appendChild(itemsElem);
            })

            const skWebLinkFragment = currentShop === "604357cc-e7e3-4e44-af31-51ce201c558c" ? "ponukakvetov" : "donaskakvetov";
            res.data.products.forEach(product => {
                var itemsElem = doc.createElement("url");
                
                var locEl = doc.createElement("loc");
                locEl.innerHTML = statics[currentShop].webLink + statics[currentShop].skProductPath + escapeXML(product.url)
                itemsElem.appendChild(locEl);
               
                if( statics[currentShop].hasLocalization ) {
                    var enLink = doc.createElement("xhtml:link");
                    enLink.setAttribute('rel', 'alternate');
                    enLink.setAttribute('hreflang', 'en')
                    enLink.setAttribute('href', statics[currentShop].webLink + "/en/flowersdelivery/product/" + escapeXML(product.url))
                    itemsElem.appendChild(enLink);
                    
                    var czLink = doc.createElement("xhtml:link");
                    czLink.setAttribute('rel', 'alternate');
                    czLink.setAttribute('hreflang', 'cs')
                    czLink.setAttribute('href', statics[currentShop].webLink + "/cz/dorucovani-kvetin/product/" + escapeXML(product.url))
                    itemsElem.appendChild(czLink);

                    var skLink = doc.createElement("xhtml:link");
                    skLink.setAttribute('rel', 'alternate');
                    skLink.setAttribute('hreflang', 'sk')
                    skLink.setAttribute('href', statics[currentShop].webLink + `/sk/${skWebLinkFragment}/product/` + escapeXML(product.url))
                    itemsElem.appendChild(skLink);
                }
                
                var lastMod = doc.createElement("lastmod");
                lastMod.innerHTML =  new Date(product.updated * 1000).toISOString()
                itemsElem.appendChild(lastMod);
                
                
                rss.appendChild(itemsElem);
            })
    
            doc.appendChild(rss);
            var serializer = new XMLSerializer();
            
            let xml =  beautify('<?xml version="1.0" encoding="utf-8"?>' + serializer.serializeToString(doc))
         
            const file = new Blob([xml], {type: 'xml'});
            let a = document.createElement('a');
            a.href = URL.createObjectURL(file);
            a.download = `sitemap_${statics[currentShop].name}.xml`;
            a.click();
        })
        .catch((e) => {
            console.error(e)
        })
        
      
    }

    return (
        <div>
            {!token ? (
                <Fragment>
                    <div >
                        <h1 style={{ margin: '10px' }}>Authorization</h1>
                        <input
                            style={{ margin: '10px', fontSize: "1.5em" }}
                            value={tokenInput}
                            type="password"
                            onChange={(e) => setTokenInput(e.target.value)}
                        ></input>
                        <br />
                        <button
                            style={{ margin: '10px' }}
                            onClick={() => authorize()}
                        >
                            Send
                        </button>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <div className="dates-wrapper">
                        <div className="dates-list-header">
                            <h2> Disabled dates </h2>
                            <div>
                                <DatePicker
                                    onChange={(e) => {
                                        addDate(e)
                                    }}
                                    customInput={<button>Add new</button>}
                                    locale="sk"
                                    dateFormat="d.M.y"
                                />
                            </div>
                        </div>

                        {dates?.map((date, index) => {
                            return (
                                <div className="dates">
                                    <h1>{moment(date).format('LL')}</h1>
                                    <button
                                        className="button red"
                                        onClick={() => deleteDate(index)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            )
                        })}
                    </div>

                    <hr />

                    <div className="dates-wrapper">
                        <div className="dates-list-header">
                            <h2> Product Catalog </h2>
                            <select
                                value={currentShop}
                                onChange={e => {
                                    setCurrentShop(e.target.value);
                                }}
                                >
                                <option value="5cd421f2-2175-4a8a-bc70-9425b19a93f8">mrrose</option>
                                <option value="604357cc-e7e3-4e44-af31-51ce201c558c">donaskakvetov</option>
                                <option value="b809bee5-8c74-4411-9788-ef92d7a146dd">ruze.sk</option>

                            </select>
                        </div>
                        <div className="d-flex justify-content-between catalog-buttons">
                            <button className='mr-2' onClick={() => getXmlFeed()}> Generate Feed </button>
                            <button onClick={() => getXmlSitemap()}> Generate Sitemap </button>

                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    )
}

export default App
