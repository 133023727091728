export const statics = 
{
    "5cd421f2-2175-4a8a-bc70-9425b19a93f8":  {
        hasLocalization: true,
        webLink: "https://www.mrrose.sk",
        webUrl: "https://www.mrrose.sk/sk/donaskakvetov",
        skProductPath: "/sk/donaskakvetov/product/",
        name:"mrrose",
        websites:[
        "https://www.mrrose.sk/sk/donaskakvetov",
        "https://www.mrrose.sk/sk/faq",
        "https://www.mrrose.sk/sk/sposoby-donasky-kvetov",
        "https://www.mrrose.sk/sk/telefonicka-objednavka-kvetov",
        "https://www.mrrose.sk/sk/o-nas",
        "https://www.mrrose.sk/sk/vseobecne-obchodne-podmienky",
        "https://www.mrrose.sk/sk/o-kvetoch",
        "https://www.mrrose.sk/sk/starostlivost-o-kvety",
        "https://www.mrrose.sk/sk/kytica-alebo-bonboniera",
        "https://www.mrrose.sk/sk/farby-ruzi",
        "https://www.mrrose.sk/sk/co-prezradi-kvet",
        "https://www.mrrose.sk/sk/kvety-na-valentina",
        "https://www.mrrose.sk/sk/jarne-kvety-do-interieru",
        "https://www.mrrose.sk/sk/kvety-na-mdz",
        "https://www.mrrose.sk/sk/ruza-kralovna-kvetov",
        "https://www.mrrose.sk/sk/kvety-podla-znamenia",
        "https://www.mrrose.sk/sk/zaujimavosti-o-tulipanoch",
        "https://www.mrrose.sk/sk/trendy-2021",
        "https://www.mrrose.sk/sk/originalna-donaska-kvetov",
        "https://www.mrrose.sk/sk/ako-si-sprijemnit-home-office-kvetmi",
        "https://www.mrrose.sk/sk/kvety-na-den-matiek",
        "https://www.mrrose.sk/sk/preco-potesit-blizkych-kyticou-z-donasky-kvetov",
        "https://www.mrrose.sk/sk/ako-sa-starat-o-orchideu",
        "https://www.mrrose.sk/sk/kvety-na-den-otcov",
        "https://www.mrrose.sk/sk/ako-vybrat-kyticu-pre-muza",
        "https://www.mrrose.sk/sk/chyby-pri-starostlivosti-o-kvety",
        "https://www.mrrose.sk/sk/tipy-na-vyber-kvetin-do-kancelarie",
        "https://www.mrrose.sk/sk/alternativne-riesenie-sporov",
        "https://www.mrrose.sk/sk/kontakt",
        "https://www.mrrose.sk/sk/login",
        "https://www.mrrose.sk/sk/mesta",
        "https://www.mrrose.sk/sk/donaskakvetov-martin",
        "https://www.mrrose.sk/sk/donaskakvetov-poprad",
        "https://www.mrrose.sk/sk/donaskakvetov-humenne",
        "https://www.mrrose.sk/sk/donaskakvetov-bardejov",
        "https://www.mrrose.sk/sk/donaskakvetov-bratislava",
        "https://www.mrrose.sk/sk/donaskakvetov-presov",
        "https://www.mrrose.sk/sk/donaskakvetov-stara-lubovna",
        "https://www.mrrose.sk/sk/donaskakvetov-michalovce",
        "https://www.mrrose.sk/sk/donaskakvetov-levoca",
        "https://www.mrrose.sk/sk/donaskakvetov-spisska-nova-ves",
        "https://www.mrrose.sk/sk/donaskakvetov-trencin",
        "https://www.mrrose.sk/sk/donaskakvetov-nitra",
        "https://www.mrrose.sk/sk/donaskakvetov-snina",
        "https://www.mrrose.sk/sk/donaskakvetov-kezmarok",
        "https://www.mrrose.sk/sk/donaskakvetov-banska-bystrica",
        "https://www.mrrose.sk/sk/donaskakvetov-kosice",
        "https://www.mrrose.sk/sk/donaskakvetov-trebisov",
        "https://www.mrrose.sk/sk/donaskakvetov-zilina",
        "https://www.mrrose.sk/sk/donaskakvetov-trnava",
        "https://www.mrrose.sk/sk/donaskakvetov-vranov-nad-toplou"
]
    },
"604357cc-e7e3-4e44-af31-51ce201c558c":  {
    hasLocalization: true,
    webLink: "https://www.donaskakvetovkosice.sk",
    webUrl: "https://www.donaskakvetovkosice.sk/sk/ponukakvetov",
    skProductPath: "/sk/ponukakvetov/product/",
    name:"donaskakosice",
    websites:[
        "https://www.donaskakvetovkosice.sk/sk/ponukakvetov",
        "https://www.donaskakvetovkosice.sk/sk/faq",
        "https://www.donaskakvetovkosice.sk/sk/sposoby-donasky-kvetov",
        "https://www.donaskakvetovkosice.sk/sk/telefonicka-objednavka-kvetov",
        "https://www.donaskakvetovkosice.sk/sk/o-nas",
        "https://www.donaskakvetovkosice.sk/sk/vseobecne-obchodne-podmienky",
        "https://www.donaskakvetovkosice.sk/sk/o-kvetoch",
        "https://www.donaskakvetovkosice.sk/sk/kvety-v-crepniku",
        "https://www.donaskakvetovkosice.sk/sk/umele-kvety",
        "https://www.donaskakvetovkosice.sk/sk/kvety-na-pohreb",
        "https://www.donaskakvetovkosice.sk/sk/donaska-k-vam",
        "https://www.donaskakvetovkosice.sk/sk/stolne-kvety",
        "https://www.donaskakvetovkosice.sk/sk/kontakt",
        "https://www.donaskakvetovkosice.sk/sk/login",
]
},
// "/product/:url"...
"b809bee5-8c74-4411-9788-ef92d7a146dd": {
    hasLocalization: false,
    webLink: "https://www.ruze.sk",
    webUrl: "https://www.ruze.sk/",
    name:"ruze",
    skProductPath: "/product/",
    websites:[
        "https://www.ruze.sk/app/moje-objednavky",
        "https://www.ruze.sk/login-register",
        "https://www.ruze.sk/domov",
        "https://www.ruze.sk/galeria",
        "https://www.ruze.sk/ponuka-kvetov",
        "https://www.ruze.sk/samostatne-kvety-detail",
        "https://www.ruze.sk/samostatne-kvety",
        "https://www.ruze.sk/vyber-kvetinara",
        "https://www.ruze.sk/shop-grid-filter",
        "https://www.ruze.sk/blog-standard",
        "https://www.ruze.sk/blog-no-sidebar",
        "https://www.ruze.sk/druhy-ruzi",
        "https://www.ruze.sk/tipy-a-triky-ako-udrzat-kyticu-dlho-cerstvu-a-sviezu",
        "https://www.ruze.sk/ako-sa-starat-o-rezane-ruze",
        "https://www.ruze.sk/aku-kyticu-ruzi-vybrat-podla-prilezitosti",
        "https://www.ruze.sk/co-hovori-farba-darovanych-ruzi",
        "https://www.ruze.sk/5-crepovych-kvetov",
        "https://www.ruze.sk/karafiaty",
        "https://www.ruze.sk/chryzantemy",
        "https://www.ruze.sk/kvety-na-prve-rande",
        "https://www.ruze.sk/vonave-kvety",
        "https://www.ruze.sk/kvety-na-hrob",
        "https://www.ruze.sk/lalie",
        "https://www.ruze.sk/orchidea",
        "https://www.ruze.sk/about",
        "https://www.ruze.sk/kontakt",
        "https://www.ruze.sk/telefonicky-kontakt",
        "https://www.ruze.sk/blog",
        "https://www.ruze.sk/faq",
        "https://www.ruze.sk/delivery-options"

    ]   
}
}
