import AppRoutes from './router/Router'
import './assets/style.css'

export const App = () => {
    return (
        <div>
            <AppRoutes />
        </div>
    )
}
